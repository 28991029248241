// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-base-landing-js": () => import("./../../../src/components/base/landing.js" /* webpackChunkName: "component---src-components-base-landing-js" */),
  "component---src-components-pages-page-js": () => import("./../../../src/components/pages/page.js" /* webpackChunkName: "component---src-components-pages-page-js" */),
  "component---src-components-projects-project-single-js": () => import("./../../../src/components/projects/ProjectSingle.js" /* webpackChunkName: "component---src-components-projects-project-single-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-post-js": () => import("./../../../src/pages/post.js" /* webpackChunkName: "component---src-pages-post-js" */)
}

